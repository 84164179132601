import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, getDocs, collection, updateDoc, increment } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

import TopBar from './TopBar';
import Footer from './Footer';
import { auth, db } from "../firebase";
import './TopicPage.css'; // Ensure CSS is appropriate for dynamic topics

import addTopicToFirestore from '../api/test';
import addNewChannelsToTopic from '../api/test1';

const TopicPage = () => {
  const { topicId } = useParams(); // Assume dynamic routing based on topic ID
  const [topicData, setTopicData] = useState({});
  const [channelVideos, setChannelVideos] = useState({});
  const [expandedView, setExpandedView] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showAddChannelPopup, setShowAddChannelPopup] = useState(false);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [newChannelName, setNewChannelName] = useState('');

  const navigate = useNavigate();

  useEffect(() => {

    // use below only when adding a new topic. will erase a lot of data so be careful. 

    // addNewChannelsToTopic("test").then(() => {
    //   console.log("Operation complete.");
    // });

    // same ^ v

    // addTopicToFirestore("physics").then(() => {
    //   console.log("Operation complete.");
    // });

    const fetchTopicData = async () => {
      const topicRef = doc(db, "topics", topicId);
      const topicSnap = await getDoc(topicRef);

      if (topicSnap.exists()) {
        setTopicData(topicSnap.data());
        topicSnap.data().channels.forEach((channel) => {
          // Fetch video details for each video ID in the channel's videos array
          const fetchVideoDetails = async () => {
            const fetchedVideos = await Promise.all(
              channel.videos.map(async (videoId) => {
                const videoRef = doc(db, 'videos', videoId);
                const videoSnap = await getDoc(videoRef);
                return videoSnap.exists() ? { id: videoSnap.id, ...videoSnap.data() } : null;
              })
            );
            // Update the channelVideos state with the fetched video details
            setChannelVideos(prev => ({
              ...prev,
              [channel.name]: {
                videos: fetchedVideos.filter(video => video !== null),
                score: channel.score || 0, // Default score if not set,
                description: channel.description || "",
                profileUrl: channel.profilePictureUrl || "",
              }
            }));
          };

          fetchVideoDetails(); // Call the function to fetch video details
        });
      }
    };

    fetchTopicData();
  }, [topicId]);

  const handleVote = async (channelName, isUpvote) => {
    const specificTopicRef = doc(db, "topics", topicId);
    const specificTopicSnapshot = await getDoc(specificTopicRef);

    if (specificTopicSnapshot.exists()) {
      let currentTopicData = specificTopicSnapshot.data();
      const channelIndex = currentTopicData.channels.findIndex(ch => ch.name === channelName);

      if (channelIndex !== -1) {
        // Grab the existing channel data
        const channel = currentTopicData.channels[channelIndex];

        // Update only the fields needed without touching the videos array or other data
        const updatedChannel = {
          ...channel,
          score: (channel.score || 0) + (isUpvote ? 1 : -1),
          upvotes: (channel.upvotes || 0) + (isUpvote ? 1 : 0),
          downvotes: (channel.downvotes || 0) + (isUpvote ? 0 : 1)
        };

        // Replace the old channel data in the array
        const updatedChannels = currentTopicData.channels.map((ch, idx) => idx === channelIndex ? updatedChannel : ch);

        // Update the entire array in Firestore
        await updateDoc(specificTopicRef, {
          channels: updatedChannels
        });

        // Update local state to reflect the change immediately in the UI
        setChannelVideos(prev => ({
          ...prev,
          [channelName]: {
            ...prev[channelName],
            ...updatedChannel,
            videos: prev[channelName].videos // Explicitly ensure videos are maintained
          }
        }));

        setShowPopup(true);
        setCurrentChannel(channelName);
      }
    } else {
      console.error("Topic not found.");
    }
  };

  const toggleView = () => {
    setExpandedView(!expandedView);
  }

  const handleAddChannel = async () => {
    if (!auth.currentUser) {
      alert("You must be signed in to add a channel.");
      return;
    }

    if (newChannelName.trim() === '') {
      alert("Channel name cannot be empty.");
      return;
    }

    const newChannel = {
      name: newChannelName.trim(),
      videos: [], // You can customize this based on your needs
      score: 0,
      upvotes: 0,
      downvotes: 0,
      description: "",
      profilePictureUrl: "https://static-00.iconduck.com/assets.00/unverified-icon-2048x2048-4kcs9ynk.png"
    };

    const specificTopicRef = doc(db, "topics", topicId);
    const specificTopicSnapshot = await getDoc(specificTopicRef);

    if (specificTopicSnapshot.exists()) {
      let currentTopicData = specificTopicSnapshot.data();

      const channelExists = currentTopicData.channels.some(ch => ch.name.toLowerCase() === newChannelName.trim().toLowerCase());
      if (channelExists) {
        alert("This channel already exists.");
        return;
      }

      await updateDoc(specificTopicRef, {
        channels: [...currentTopicData.channels, newChannel]
      });

      setTopicData(prev => ({
        ...prev,
        channels: [...prev.channels, newChannel]
      }));

      setNewChannelName('');
      setShowAddChannelPopup(false);
      alert("Channel added successfully.");
    }
  };

  return (
    <div className='topic-page-container'>
      <TopBar
        title="S-TIER STUFF"
        profileImageUrl={auth?.currentUser?.photoURL}
      />

      {topicData && topicData.name ? (
        <>


          <h1 className="topic-title">{topicData.name || "Topic Guide"}</h1>
          <p className="topic-description">{topicData.description || "Explore curated content across a variety of channels dedicated to this topic...coming soon!"}</p>

          <button className="toggle-view-button" onClick={toggleView}>
            {expandedView ? "Show Simple View" : "Show Expanded View"}
          </button>

          {showPopup && (
            <div className="popup">
              <div className="popup-inner">
                <div className="popup-inner-text">Thanks for voting :) Leave a quick review for the channel?</div>
                <button onClick={() => {
                  navigate(`/channel/${currentChannel}`);
                  setShowPopup(false); // Close the popup
                }}>
                  Review
                </button>
                <button onClick={() => setShowPopup(false)}>
                  Return
                </button>
              </div>
            </div>
          )}

          {Object.entries(channelVideos)
            .sort((a, b) => b[1].score - a[1].score)  // Sorting by score, descending
            .map(([channelName, { videos, score, description, profileUrl }]) => (
              <div key={channelName} className="channel-container">

                <div>
                  <div className="content-container">
                    <div className="vote-controls">
                      <span onClick={() => handleVote(channelName, 1)} className="vote-button upvote">↑</span>
                      <span className="score">{score}</span>
                      <span onClick={() => handleVote(channelName, 0)} className="vote-button downvote">↓</span>
                    </div>



                    <div className="all-channel-stuff">
                      <div className="topic-channel-info" onClick={() => navigate(`/channel/${channelName}`)}>
                        <img src={profileUrl} alt={`${channelName} profile`} className="topic-channel-profile-picture" />
                        <h2 className="channel-name">
                          {channelName}
                        </h2>
                      </div>

                      {expandedView &&
                        <><p className="topic-channel-description">{description}</p><div className="topic-video-list">
                          {videos.map(video => (
                            <div key={video.id} className="video-item" onClick={() => navigate(`/video/${video.id}`)}>
                              <img src={video.thumbnail?.url} alt={video.title} className="video-thumbnail" />
                              <p>{video.title}</p>
                            </div>
                          ))}
                        </div></>
                      }

                    </div>

                  </div>

                </div>
                {/* } */}

              </div>
            ))
          }

          {/* Add Channel Button */}
          {auth.currentUser ? (
            <div className="add-channel-button-container">
              <button className="add-channel-button" onClick={() => setShowAddChannelPopup(true)}>
                Add Channel
              </button>
            </div>
          ) : (
            <div className="add-channel-container">
              <br />
              Sign in to add a new channel!
              <br />
            </div>
          )}

          {/* Add Channel Popup */}
          {showAddChannelPopup && (
            <div className="popup">
              <div className="popup-inner">
                <h2>Add a New Channel</h2>
                <input
                  type="text"
                  value={newChannelName}
                  onChange={(e) => setNewChannelName(e.target.value)}
                  placeholder="Channel name"
                />
                <div className="popup-buttons">
                  <br></br>
                  <button onClick={handleAddChannel}>Submit</button>
                  <button onClick={() => setShowAddChannelPopup(false)}>Cancel</button>
                </div>
              </div>
            </div>
          )}
        </>


      ) : topicData && !topicData.name ? (
        <div className="topic-not-found">
          <h2>Topic Not Found</h2>
          <p>The topic you are looking for does not exist (yet?)</p>
        </div>
      ) : (

        <div className="loading">
          <p>Loading...</p>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default TopicPage;
